@mixin space-generator($grid-columns, $prop, $short) {
  @for $i from 0 through $grid-columns {
    .#{$short}-#{$i} {
      #{$prop}: #{$i}rem !important;
    }
  }
}

@mixin width-generator($max-percentage, $short) {
  @for $i from 0 through $max-percentage {
    .#{$short}-#{$i} {
      width: #{$i}#{"%"};
    }
  }
}

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: 599px) {
      @content;
    }
  } @else if $size == tablet-portrait-up {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $size == tablet-landscape-up {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $size == desktop-up {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $size == big-desktop-up {
    @media (min-width: 1800px) {
      @content;
    }
  }
}

@include space-generator(10, "margin", "m");
@include space-generator(10, "margin-bottom", "mb");
@include space-generator(10, "margin-top", "mt");
@include space-generator(10, "margin-left", "ml");
@include space-generator(10, "margin-right", "mr");
@include space-generator(10, "padding", "pd");
@include space-generator(10, "padding-left", "pl");
@include space-generator(10, "padding-right", "pr");
@include space-generator(10, "padding-bottom", "pb");
@include space-generator(10, "padding-top", "pt");
@include space-generator(10, "padding", "p");

@include width-generator(100, "w");

.p-xs {
  padding: 0.5rem;
}

.ml-auto {
  margin-left: auto;
}

.text-bold {
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}

.h-100 {
  height: 100%;
}

.text-center {
  text-align: center;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-betwee {
  justify-content: space-between;
}

.border-radius {
  &--sm {
    border-radius: 4px;
  }
}
