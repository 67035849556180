input.inteach-input {
  height: 2.5rem;
  width: 100%;
  border: none;
  border-radius: 5px;
  background-color: #efeff2;
  padding: 0 15px;

  &--round {
    border-radius: 40px;
  }
}
