@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  src: url("./fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  src: url("./fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  src: url("./fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "SFPro";
  font-weight: 700;
  src: url("./fonts/SFProText-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "SFPro";
  font-weight: 500;
  src: url("./fonts/SFProText-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "SFPro";
  font-weight: 400;
  src: url("./fonts/SFProText-Regular.ttf") format("truetype");
}
