.inteach-button {
  border-radius: 20px;
  padding: 10px 15px;
  border: none;

  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }

  &--loading {
    cursor: wait !important;
    color: white;
    background-color: var($gray-700);
  }

  &--primary {
    color: white;
    background-color: var(--main-color);
  }

  &--block {
    width: 100%;
  }

  &--sm {
    font-size: small;
  }

  &--md {
    font-size: medium;
  }

  &--lg {
    font-size: large;
  }

  &--block {
    width: 100%;
  }
}
