$gray-200: #F8F8F8;
$gray-300: #DFE0E5;
$gray-400: #EFEFF2;
$gray-500: #D8D8D8;
$gray-600: #7F8592;
$gray-700: #70747C;
$gray-800: #333436;

$blue-500: #1B2749;
$blue-600: #222B45;
$blue-700: #103338;

$gold: #FFAD00;