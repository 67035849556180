.inteach-capsule-reward {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;

  &__title {
    font-weight: bold;
    color: black;
  }

  &__minimum-required {
    font-weight: bold;
    color: grey;
    margin-bottom: 1rem;
  }

  &__description {
    font-weight: bold;
    color: black;
    margin-bottom: 1rem;
  }

  &__points {
    color: #ffac00;
    display: flex;
    align-items: center;
    font-size: 2rem;
    font-weight: bold;
    margin: 1.5rem 0;
  }
}
