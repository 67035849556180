.inteach-tags {
  border-radius: 20px;
  font-weight: bold;
  color: var(--principal-text);
  padding: 5px 10px;
  font-size: 0.8rem;
  display: inline-block;
  margin-left: 0.5rem;

  &--selected {
    color: black;
    background-color: #d3d3d3;
  }

  &--clickable:hover {
    cursor: pointer;
    background-color: #e5e5e5;
  }
}
