.help__heading {
    font-size: 1.4rem;
    text-align: center;
    display: block;
}

.help__sub-heading {
    display: block;
    font-size: 1.2rem;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.help__section {
    margin-bottom: 1rem;
}

.help__text {
    font-size: .9rem;
    display: block;

    &--italic {
        font-style: italic;
    }
}

.help__input {
    margin-right: 1rem;
    margin-top: 1rem;
    height: 40px;
    border-width: 1px;
    border-radius: 4px;
}

.help__button-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.help__button {
    color: #FFF;
    border-radius: 4px;
}

.help__separator {
    height: 1px;
    display: block;
    background-color: #cecece;
}