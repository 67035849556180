// Sidebar Animation

.SidebarAnimation-appear {
  transform: translateX(-30px);
  opacity: 0;

  &.SidebarAnimation-appear-active {
    opacity: 1;
    transform: translateX(0);
    transition: all 0.4s linear;
  }
}

// Tabs Animation
.TabsAnimation-enter,
.TabsAnimation-appear {
  opacity: 0.01;
  transform: translate3d(0, -5px, 0);

  &.TabsAnimation-appear-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: all 0.4s linear;
  }
}

// Header Animation

.HeaderAnimation-appear {
  transform: translateY(-30px);
  opacity: 0;

  &.HeaderAnimation-appear-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.4s linear;
  }
}

// Main Animation

.MainAnimation-appear {
  transform: translateY(-30px);
  opacity: 0;

  &.MainAnimation-appear-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.4s linear;
  }
}

// Slide right

@keyframes slide-right {
  0% {
    transform: translate3d(-100px, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes scale-down-center {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
}
