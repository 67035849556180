@import "capsule-reward";
@import "practice";

.ReactModal__Overlay {
  opacity: 0;
  transform: translate3d(0, -50px, 0);
  transition: opacity 250ms ease-in-out, transform 500ms ease-out;
}

.ReactModal__Overlay--after-open {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  transform: translate3d(0, -50px, 0);
  opacity: 0;
}

.inteach-modal {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding: 0 20px;
    width: 100%;

    &--absolute {
      position: absolute;
    }
  }

  &__title {
    margin-bottom: 0;
  }

  &__close {
    display: flex;
    cursor: pointer;
    color: #70737b; // TODO
    font-size: large;
    font-weight: bold;
    border: none;
    background-color: transparent;
    z-index: 3;
  }

  &__content {
    padding-left: 20px;
    padding-right: 20px;
    height: 100%;
    overflow-x: scroll;
  }
}
