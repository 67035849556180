.post__actions {
  display: flex;
  justify-content: flex-start;
}

.post-actions__button {
  display: flex;
  margin-right: 25px;
  cursor: pointer;

  svg {
    margin-right: 10px;
  }

  span {
    color: $blue-600;
  }
}

.post__reactions {
  cursor: pointer;
}

.inteach-post-comments {
  &__container {
    cursor: initial;
    padding: 20px;
  }

  &__comment {
    margin-bottom: 10px;
  }

  &__block {
    justify-content: flex-start;
    width: 100%;
    display: flex;
  }

  &__avatar {
    flex: 5%;
  }

  &__message {
    background-color: #dfe0e5; // TODO
    border-radius: 4px;
    flex: 90%;
    padding: 10px;
  }

  &__name {
    font-weight: bold;
    font-size: 0.9rem;
  }

  &__text {
    margin: 8px 0;
    font-size: 0.8rem;
  }

  &__reactions {
    font-size: 0.8rem;

    span {
      margin-right: 10px;
    }
  }

  &__like {
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;

    button {
      border: none;
      background-color: transparent;
      text-align: right;
      display: flex;
      align-items: center;
      color: var(--principal-text);

      svg {
        margin-right: 5px;
      }
    }
  }

  &__empty h4 {
    text-align: center;
    margin: 20px 0;
  }

  &-send {
    border: 0.5px solid #dfe0e5;
    border-radius: 0 0 4px 4px;
    width: 100%;
    display: flex;
    align-items: center;

    &__icon {
      flex: 10%;
      text-align: center;

      svg {
        fill: var(--secondary);
      }
    }

    &__input {
      flex: 80%;
      border: none;
      resize: none;
      height: 40px;
      padding: 9px;
      font-size: 14px;
      font-family: SFPro;
    }

    &__submit {
      flex: 10%;
      text-align: center;
      cursor: pointer;
    }
  }
}

.post__tag {
  color: #333;
  background-color: $gray-300;
  display: inline-block;
  padding: 2px 5px;
  border-radius: 1rem;
  margin-top: 5px;
}

.post__training {
  border-top: 1px solid $gray-300;
  border-bottom: 1px solid $gray-300;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.post__training-name {
  color: $blue-600;
  font-weight: 700;
}

.post__header-date {
  color: $gray-600;
  margin-left: 0.5rem;
}

.post__img {
  height: 250px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
