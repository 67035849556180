.inteach-practice {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    margin-top: 1rem;
    color: var(--principal-text);
  }

  p {
    padding: 1rem 0 2rem;
    color: var(--grey);
  }

  button {
    width: 10rem;
  }

  a {
    text-decoration: underline;
    color: var(--grey);
    cursor: pointer;
    padding: 0.5rem 0;
  }
}
