@import "../../colors.scss";

.raf-feed-placeholder,
.raf-load-more-button {
  display: none;
}

.custom.str-chat {
  .str-chat {
    &__avatar-fallback {
      background-color: var(--main-color);
    }

    &__message-simple-status svg path {
      fill: var(--main-color);
    }
  }
}

.str-chat-channel-list .str-chat__channel-list-messenger__main {
  padding: 0;
}

.str-chat__thread {
  max-width: 100%;
}

.str-chat__message-inner {
  width: 100%;
}

.inteach.str-chat {
  .str-chat__avatar-fallback {
    background-color: $gray-200;
    font-weight: bold;
    color: #333;
  }

  .str-chat__channel-preview button {
    cursor: pointer;
  }

  .str-chat__channel-preview button:focus {
    box-shadow: 0 0 0 1px var(--main-color), inset 0 0 0 1px var(--main-color);
  }

  .str-chat__channel-preview--active {
    background: #fff;
  }

  .str-chat__channel-preview-title {
    font-weight: bold;
  }

  .str-chat__channel-preview-title,
  .str-chat__channel-preview-last-message {
    color: #333;
  }
}

@media (min-width: 840px) {
  .str-chat__send-button {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  .str-chat__message-simple-text-inner {
    max-width: none !important;
  }
}

@media screen and (min-width: 420px) {
  .str-chat__message--me .str-chat__message-inner,
  .str-chat__message-simple--me .str-chat__message-inner {
    margin-left: 0;
  }

  .str-chat__message-inner,
  .str-chat__message-simple-inner {
    margin-right: 0;
  }
}
